@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
  font-family: "Cerebri Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body {
  background-color: #f6f7f8;
}

*::-webkit-scrollbar {
	-webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
	width: 12px;
}

*::-webkit-scrollbar:horizontal {
	height: 8px;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0, 0.1);
	border-radius: 10px;
	border: 2px solid transparent;
}

*::-webkit-scrollbar-track {
	border-radius: 10px;
}

.glass-background {
  background: rgba(246, 247, 248, 0.9) !important;
  backdrop-filter: blur(16px) !important;
}