        /* Reset básico */
        html {
            scroll-behavior: smooth;
        }
        
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
        }

        /* Contêiner da imagem */
        .container {
            display: flex;
            justify-content: center; /* Centraliza a imagem */
        }

        /* Estilo da imagem */
        .container img {
            width: 30%; /* A imagem ocupa 100% da largura disponível */
            max-width: 600px; /* Limita o tamanho máximo da imagem */
            height: auto; /* Mantém a proporção da imagem */
            margin-right:52%; /*margin da imagem eduzz + bizpik porcentagem 70% */
        }
        
        /* Header */
        .header {
            background-color: #001f60;
            color: #fff;
            text-align: center;
            border-bottom-left-radius: 38px;
            border-bottom-right-radius: 38px;
            padding: 100px 20px;

        }

        .header h1 {
            font-size: 2.5em;
        }

        .header p {
            font-size: 2.5em;
            font-family: "Open Sans", sans-serif;
            font-weight: bold;
            margin-top: 10px;
            text-align: left;
            width: 50%;
            padding: 5px;
            margin-left: 7%; /*margin do texto /não tinha*/
        }

        .header h4{
            text-align: left;
            font-size: 1.3em;
            padding: 5px;
            font-family: "Open Sans", sans-serif;
            font-weight: 100;
            margin-top: 30px;
            width: 40%;
            margin-left: 7%; /*margin do texto /não tinha*/
        }

        .fixed-container {
            position:absolute;
            top: 45%; /* Distância do topo da página */
            right: 100px; /* Distância da borda direita da página / margem da imagem com botão 60px */
            z-index: 1000; /* Garantir que a imagem fique acima de outros elementos */
            display: inline-block;
            align-items: center;
        }

        /* Estilo da imagem */
        .fixed-container img {
            width: 380px; /* Largura da imagem */
            height: auto; /* Mantém a proporção da imagem */
            margin-right: 60px; 
        }


        .button-primary {
            position: absolute; 
            bottom: 40px;
            right: 30%;
            background-color: #001f60;
            width: 50%;
            font-family: "Open Sans", sans-serif;
            font-weight: bolder;
            color: #f4f4f4;
            text-align: center;
            padding: 15px;
            border: none;
            border-radius: 46px;
            font-size: 1em;
            cursor: pointer;
            text-decoration: none;
            display: inline-block;
            box-shadow: 0 2px 5px #0003;
        }
        .button-primary:hover {
            background-color: rgb(212, 103, 0);
            box-shadow: 0 2px 5px #0003;
          }

        /* Benefícios */
        .benefits {
            text-align: center;
            padding: 0px;
            margin-top: 50px;
            position: relative;
            display: flex;
            line-height: 6.0em;

            /* justify-content: center; */
            width: 100vw; /* Ocupa toda a largura da viewport */
        }
        
        /* Container com imagem e texto */
        .benefits-container {
            position: relative;
            width: 100vw; /* Ocupa toda a largura da viewport */
            /* max-width: 900px;  Ajusta largura máxima  */
            /* height: 100vw; */
            /* text-align: center; */
            /* padding: 0px; */
            /* margin-top: 50px; */
            /* position: relative; */
            /* display: flex; */
        }
        
        /* Imagem */
        .benefits-container img {
            width: 100vw; /* Ocupa toda a largura da viewport */
            height: auto;
             /* Bordas arredondadas */
        }
        
        /* Texto sobre a imagem */
        .benefits-text {
            position: absolute;
            top: 50%; /* Centraliza verticalmente */
            left: 50%; /* Centraliza horizontalmente */
            transform: translate(-50%, -50%); /* Ajusta para centralização perfeita */
            text-align: center;
            color: #f8f8f8; /* Cor branca para o texto */
            padding: 20px;
            border-radius: 10px; /* Bordas arredondadas do fundo */
        }
        
        /* Estilo do título */
        .benefits-text h2 {
            font-family: "Open Sans", sans-serif;
            font-weight: bolder;
            font-size: 2.5em;
        }

        .benefits-text h3{
            font-family: "Open Sans", sans-serif;
            font-weight: bolder;
            margin-bottom: 10px;
            font-size: 1.4em;
        }
        
        /* Estilo do parágrafo */
        .benefits-text p {
            font-family: "Open Sans", sans-serif;
            font-size: 1.2em;
            font-weight: bold;
            line-height: 1.6em;
        }

        .subtitle p{
            font-family: "Open Sans", sans-serif;
            font-size: 1.6em;
            font-weight:600;
            color: #FBB90C;
            margin-top: 40px;
            text-align: center;
            /* margin-left: 40px; */
        }
       
        /* Planos Basic*/
        .plans {
            display: flex;
            flex-wrap: wrap;
            gap: 130px;
            justify-content: center;
            padding: 40px 20px;
            max-width: 1200px;
            margin: auto;
        }

        .plan {
            flex: 1 1 300px;
            border: 2px solid #001f60;
            border-top-left-radius: 38px;
            border-top-right-radius: 38px;
            border-bottom-right-radius: 38px;
            padding: 60px;
            min-width: 450px;
            max-width: 350px;
        }

       .plan hr {
            border: none; /* Remove a borda padrão */
            height: 2px; /* Define a altura da linha */
            background-color: #801AFF; /* Define a cor da linha */
            width: 100%; /* Define a largura da linha (pode ser em % ou px) */
            margin: 20px auto; /* Centraliza a linha e adiciona margens */
        }

        .wrapper {
            display: flex; /* Usa Flexbox para colocar a linha e os textos lado a lado */
            align-items: center; /* Centraliza os itens verticalmente */
            gap: 10px; /* Espaço entre a linha e o texto */
            margin-bottom: 20px;
        }
        
        .vertical-line {
            width: 2px; /* Largura da linha vertical */
            background-color: #000; /* Cor da linha */
            height: 100px; /* Altura da linha (ajuste conforme necessário) */
            width: 4px;
            height: 15vh; 
            background-color: #801AFF;
            margin-left: 50% auto;
        }
        
        .text-group {
            display: flex;
            flex-direction: column; /* Coloca os textos em uma coluna */
            gap: -10px; /* Espaço entre os parágrafos */
        }
        
        .priceold, .desconto {
            margin: 0; /* Remove margens para evitar espaçamento indesejado */
            font-size: 1.3em;
            font-family: "Open Sans", sans-serif;
            color:#801AFF;
        }

        .priceold{
            color: #7C8593;
            font-size: 1.3em;
            font-weight: bold;
        }

        .price1{
            font-weight: bolder;
            margin: 0; /* Remove margens para evitar espaçamento indesejado */
            font-size: 1.8em;
            font-family: "Open Sans", sans-serif;
            color:#801AFF;
        }

        .pricedesconto{
            color: #7C8593;
            font-size: 1.0em;
            font-weight: bold;
            top: 30px;
        }

        .container-grid {
            display: grid;
            gap: 1px; /* Espaço entre cada linha de item */
        }
        
        .item {
            display: grid;
            grid-template-columns: auto 10fr; /* Define uma coluna para a imagem e outra para o texto */
            align-items: center; /* Centraliza verticalmente a imagem e o texto */
            
        }
        
        .image {
            width: 20px;
            height: auto;
        }
        
        .texto {
            font-size: 0.9em;
            margin: 7px 0;
            margin-left: 5px;
        }

        .plan-basic {
            border-color: #801AFF;
        }

        .plan-basic h3 {
            font-size: 2.4em;
            margin-bottom: 10px;
            font-family: "Open Sans", sans-serif;
            color:#801AFF;
            font-weight: bold;
        }

        .prod {
            font-size: 1.0em;
            font-family: "Open Sans", sans-serif;
            color:#801AFF;
            
        }

        .titulo{
            font-size: 1.3em;
            font-family: "Open Sans", sans-serif;
            color:#000000;
            font-weight: bolder;
            margin-top: 20px;
        }

        .button-plan1 {
            background-color: #801AFF;
            color: #fff;
            font-weight: bold;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-decoration: none;
            align-items: center;
            margin-top: 380px;
            display: flex;
            justify-content: center; /* Centraliza horizontalmente */
            align-items: center; /* Centraliza verticalmente, se necessário */
        }
        .button-plan1:hover {
            background-color: #4b01a5;
          }


        .texto2 {
            font-family: "Open Sans", sans-serif;
            color: #7C8593; /* Cor do texto */
            line-height: 2.0; /* Espaçamento entre linhas */
        }
        
        .texto2 h3 {
            font-size: 1em;
            color: #7C8593;
            font-weight: bold;
            margin-top: 5px;
        }
        
        .texto2 ul {
            list-style-type: disc; /* Adiciona marcadores de lista */
            padding-left: 40px; /* Recuo para alinhar as listas */
            margin: 0;
        }
        
        .texto2 li {
            margin-bottom: 1px; 
        }

        /* Plano Pro */

        .plan-pro{
            border-color: #09bd5a;
        }

        .plan-pro h3{
            font-size: 2.4em;
            margin-bottom: 10px;
            font-family: "Open Sans", sans-serif;
            color:#09bd5a;
            font-weight: bold;
        }

        .prod2 {
            font-size: 1.0em;
            font-family: "Open Sans", sans-serif;
            color:#09bd5a;
            
        }

        .plan-pro hr {
            border: none; /* Remove a borda padrão */
            height: 2px; /* Define a altura da linha */
            background-color: #09bd5a; /* Define a cor da linha */
            width: 100%; /* Define a largura da linha (pode ser em % ou px) */
            margin: 20px auto; /* Centraliza a linha e adiciona margens */
        }

        .vertical-line2 {
            width: 2px; /* Largura da linha vertical */
            background-color: #000; /* Cor da linha */
            height: 100px; /* Altura da linha (ajuste conforme necessário) */
            width: 4px;
            height: 15vh; 
            background-color: #09bd5a;
            margin-left: 50% auto;
        }

        .price2{
            font-weight: bolder;
            margin: 0; /* Remove margens para evitar espaçamento indesejado */
            font-size: 1.8em;
            font-family: "Open Sans", sans-serif;
            color:#09bd5a;
        }

        .texto3 {
            font-family: "Open Sans", sans-serif;
            color: #7C8593; /* Cor do texto */
            line-height: 2.0; /* Espaçamento entre linhas */
        }
        
        .texto3 h3 {
            font-size: 1em;
            color: #7C8593;
            font-weight: bold;
            margin-top: 5px;
        }
        
        .texto3 ul {
            list-style-type: disc; /* Adiciona marcadores de lista */
            padding-left: 40px; /* Recuo para alinhar as listas */
            margin: 0;
        }
        
        .texto3 li {
            margin-bottom: 1px; 
        }

        .button-plan2 {
            background-color: #09bd5a;
            color: #fff;
            font-weight: bold;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-decoration: none;
            align-items: center;
            margin-top: 15px;
            display: flex;
            justify-content: center; /* Centraliza horizontalmente */
            align-items: center; /* Centraliza verticalmente, se necessário */
        }
        .button-plan2:hover {
            background-color: #00853c;
          }

        /* Depoimentos */
        .carousel {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 20px;
        }
        
        .carousel-container {
            display: flex;
            gap: 20px;
            overflow-x: auto;  /* Permite a rolagem horizontal */
            scroll-snap-type: x mandatory;  /* Faz com que os cartões "encaixem" ao rolar */
            padding-bottom: 10px;
        }
        
        .carousel-container::-webkit-scrollbar {
            height: 8px;
        }
        
        .carousel-container::-webkit-scrollbar-thumb {
            background-color: #B7B7B7;
            border-radius: 10px;
        }
        
        .testimonial-card {
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            padding: 20px;
            width: 300px;
            min-width: 380px;
            scroll-snap-align: start;  /* Alinha cada cartão ao início durante a rolagem */
        }
        
        .testimonial-image {
            width: 100px;
            height: 100px;
            margin-right: 15px;
        }
        
        .testimonial-content {
            display: flex;
            flex-direction: column;
        }
        
        .testimonial-content h3 {
            font-size: 1.1em;
            margin-bottom: 5px;
        }
        
        .testimonial-content p {
            font-size: 0.9em;
            color: #555;
        }
        
        .stars {
            color: #f4b400;
            margin-top: 5px;
            font-size: 1.2em;
        }

        /* Benefícios 2 */
      
        .benefits2 {
            text-align: center;
            /* padding: 0px; */
            margin-top: 50px;
            position: relative;
            display: flex;
            /* justify-content: center; */
        }
        
        /* Container com imagem e texto */
        .benefits-container2 {
            position: relative;
            width: 100%;
            max-width: 900px; /* Ajusta largura máxima */
        }
        
        /* Imagem */
        .benefits-container2 img {
            width: 100%; /* Imagem responsiva */
            height: auto;
             /* Bordas arredondadas */
        }
        
        /* Texto sobre a imagem */
        .benefits-text2 {
            position: absolute;
            top: 50%; /* Centraliza verticalmente */
            left: 50%; /* Centraliza horizontalmente */
            transform: translate(-50%, -50%); /* Ajusta para centralização perfeita */
            text-align: center;
            color: #fff; /* Cor branca para o texto */
            /* background: rgba(0, 0, 0, 0.5); Fundo semi-transparente */
            padding: 20px;
            border-radius: 10px; /* Bordas arredondadas do fundo */
        }
        
        /* Estilo do título */
        .benefits-text2 h2 {
            font-family: "Open Sans", sans-serif;
            font-weight: bolder;
            font-size: 2.0em;
            margin-bottom: 10px;
        }

      

      .button-primary2 {
          background-color: #FBB90C;
          font-family: "Open Sans", sans-serif;
          font-weight: bolder;
          color: #000000;
          padding: 15px 30px;
          border: none;
          border-radius: 46px;
          font-size: 1em;
          cursor: pointer;
          text-decoration: none;
          display: inline-block;
          width: 100%; /* Largura total em dispositivos móveis */
          max-width: 300px; /* Define um limite para o botão em telas grandes */
          margin: 0 auto; /* Centraliza o botão */
          box-shadow: 0 2px 5px #0003;
        }

        .button-primary2:hover {
            background-color: #0047e0;
            color: #fff;
            box-shadow: 0 2px 5px #0003;
        }


        /* FAQ */
        
        .accordion {
            
            display: flex;
            flex-direction: column;
            /* gap: 10px; */
            max-width: 800px; /* Define uma largura máxima para centralizar */
            margin: 0 auto; /* Centraliza o acordeão na tela */
            width: 90%; /* Ocupa 90% da largura da tela em telas pequenas */
        }

        .cssaccordion{
          background-color: rgb(255, 255, 255);
          color: rgb(34, 38, 42);
          width: 60%;
          margin: 0 auto;
          padding-top: 24px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
          border-radius: 0px;
          margin-bottom: 100px;
          padding-bottom: 24px;
        }

         #text_acordion{
            margin: 20px 30px;
        }

        /* Responsividade  DESKTOP*/
        @media only screen and (max-width: 1440px) {
            .container img {
                /* position: relative; */
                margin-right: 55%;
            }
            .fixed-container {
                /* max-width: 50%; A imagem ocupa 100% da largura da tela em dispositivos pequenos */
                /* position: relative; */
                top: 380px;
                align-items: center;
                /* transform: translate(13%); */
            }
            .benefits-content img {
                width: 1500px; /* Ocupa toda a largura da viewport */
                height: auto;
        }
        .benefits {
            line-height: 3.0em;
        }
        .benefits-content2 img{
            width: 1500px; /* Ocupa toda a largura da viewport */
            height: auto;
            
        }
    }
        

        @media (max-width: 1024px) {

            .container img {
                margin-right: 55%;
            }
            
        .benefits-content img {
             width: 1500px; /* Ocupa toda a largura da viewport */
            height: auto;
        }
        .benefits-content2 img{
            width: 1500px; /* Ocupa toda a largura da viewport */
            height: auto;
        }

        .fixed-container {
            position: absolute;
            top: 480px;
            align-items: center;
            transform: translate(25%);
        }

        }
        /* only screen and  */
        /* Responsividade  TABLET*/

        @media (max-width: 768px) {
            .plans {
                flex-direction: column;
                align-items: center;            
            }

            .header p {
                font-size: 1.6em; /* Diminui o tamanho da fonte */     
                width: 80%;
            }
            .header h4{
                width: 80%;
            }

            .fixed-container {
                /* max-width: 50%;  */
                position: relative;
                top: 40px;
                align-items: center;
                transform: translate(70%);
            }
                       
            .container {
                max-width: 0;
                margin-left: 9%;
            }

            .container img{
               width: 300px;
               height: auto; /* Mantém a proporção da imagem */
               /* margin-left: 40%; */
            }
        
            .button-primary {
                font-size: 0.8em; /* Ajusta o tamanho da fonte do botão em telas menores */
                padding: 12px 18px; /* Ajusta o tamanho do padding no botão */
            }

            .carousel-container {
                flex-direction: row; /* Continua com os cartões lado a lado em telas menores */
            }
        
            .testimonial-card {
                max-width: 90%; /* Reduz a largura dos cartões */
            }

            .benefits-text h2 {
                font-size: 2.0em; 
                /* width: 700px; */
            }

            .benefits-text2 h2 {
                font-size: 1.7em; /* Ajusta título para telas menores */
                width: 660px;
            }
        
            .benefits-text p {
                font-size: 1.3em; /* Ajusta parágrafo */
                width: 750px;
            }

            .benefits-text h3 {
                font-size: 1.6em;
                /* width: 400px; */
                
            }
        
            .benefits-text {
                padding: 10px; /* Reduz padding em dispositivos menores */
                margin-top: 10px;
            }
        
            .button-primary2 {
                font-size: 1.0em; /* Ajusta o tamanho da fonte para caber melhor */
                padding: 10px 40px; /* Reduz o padding para dispositivos menores */
            }

            /* .accordion__header {
                font-size: 0.9em;
                padding: 10px 15px;
            }
            
            .accordion__item .accordion__content {
                padding: 0 15px;
                font-size: 0.85em;
            } */
        }

        /* @media (max-width: 425px){

            .fixed-container {
                height: auto;
                margin-right: 30px;
                width: 380px;
            }
        } */

        /* Responsividade  SMARTPHONE ESTAVA 480*/

        @media (max-width: 480px) and (max-width: 425px){ 
            .plans {
                flex-direction: column;
                align-items: center;
                max-width: 1200px;
                margin: auto;
            }
    
            .plan {
                /* flex: 1 1 300px; */
                min-width: 150px;
                /* max-width: 350px; */
            }

            .header p {
                font-size: 1.6em; /* Diminui o tamanho da fonte */     
                width: 90%;
                margin-left: 5px;
            }
            .header h4{
                width: 100%;
                margin-left: 1%;
            }

            .fixed-container {
                /* max-width: 50%; A imagem ocupa 100% da largura da tela em dispositivos pequenos */
                position: relative;
                top: 40px;
                align-items: center;
                transform: translate(30%);
            }

            .fixed-container img {
                width: 380px;
                height: auto;
                margin-right: 50px;
            }

            .container {
                max-width: 0;
                margin-left: 40%;
            }

            .container img{
               width: 300px;
               height: auto; /* Mantém a proporção da imagem */
               margin-left: 10px;
            }
        
            .button-primary {
                font-size: 0.8em; /* Ajusta o tamanho da fonte do botão em telas menores */
                padding: 12px 18px; /* Ajusta o tamanho do padding no botão */
            }

            .carousel-container {
                flex-direction: row; /* Continua com os cartões lado a lado em telas menores */
            }
        
            .testimonial-card {
                max-width: 90%; /* Reduz a largura dos cartões */
            }
 
            .benefits-text h2 {
                font-size: 1.0em; 
            } 

            .benefits-text2 h2 {
                font-size: 1.0em; /* Ajusta título para telas menores */
                width: 400px;
            }
        
            .benefits-text p {
                font-size: 0.7em;
                width: 400px; /* Ajusta parágrafo */
            }

            .benefits-text h3 {
                font-size: 0.9em;
                
            }
        
            .benefits-text {
                padding: 10px; /* Reduz padding em dispositivos menores */
                margin-top: 10px;
            }
        
            .button-primary2 {
                font-size: 0.7em; /* Ajusta o tamanho da fonte para caber melhor */
                padding: 7px 20px; /* Reduz o padding para dispositivos menores */
            }
        }

        @media (max-width: 375px){

            .container {
                max-width: 0;
                margin-left: 45%;
            }

            .benefits-text p {
                /* font-size: 0.7em; */
                width: 300px; /* Ajusta parágrafo */
            }

            .benefits-text{
                line-height: 1.0em;
            }
            .fixed-container {
                /* max-width: 50%; A imagem ocupa 100% da largura da tela em dispositivos pequenos */
                position: relative;
                top: 40px;
                align-items: center;
                transform: translate(26%);
            }
            .button-primary {
                font-size: 0.8em;
                padding: 12px 18px;
                transform: translate(10%);
            }
    }
     @media only screen and (max-width: 2560px){

            .benefits-content img {
            width: 2600px; /* Ocupa toda a largura da viewport */
            height: auto;
            }

            .benefits-content2 img {
             width: 2600px; /* Ocupa toda a largura da viewport */
             height: auto;
             }
    }
